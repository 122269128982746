import Connection from '../utils/Connection';
import { EXPRESS_URL } from '../utils/Macros';

export async function getTests(page: number, results: number, search: string) {
    return await Connection.get(`${EXPRESS_URL}tests?page=${page}&per_page=${results}&query=${search}`)
}

export async function getFree() {
    return await Connection.get(`${EXPRESS_URL}simulations/free`)
}

export async function getJustPublished(page: number, results: number, search: string) {
    return await Connection.get(`${EXPRESS_URL}tests/justPublished?page=${page}&per_page=${results}&query=${search}`)
}

export async function getTestByID(testID: string) {
    return await Connection.get(`${EXPRESS_URL}tests/${testID}`)
}

export async function getUsersPercentage(testID: string, simuID: string, customerID: string) {
    return await Connection.get(`${EXPRESS_URL}tests/getPercentage/${testID}/${simuID}/${customerID}`)
}

export async function editTest(testID: string, data: any) {
    return await Connection.put(`${EXPRESS_URL}tests/${testID}`, data)
}

export async function newTest(data: any) {
    return await Connection.post(`${EXPRESS_URL}tests/`, data)
}

export async function deleteTest(id: any) {
    return await Connection.delete(`${EXPRESS_URL}tests/${id}`);
}

export async function checkEanOnAppro(ean: string) {
    return await Connection.checkEanAppro(`https://approfondimenti.maggiolicloud.it/auth/api/v2/get/books/attachmentsByIsbn/${ean}` )
}