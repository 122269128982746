import React from 'react'
import { 
	Banner, 
	Detail, 
	H3,
} from '@maggioli-design-system/react'
import { useHistory } from 'react-router-dom'


export default function BannerModuli() {

	const history = useHistory();

	const onClickGuide = () => {
		history.push("/guida")
	}

    return (
        <div className="gap-10 view-limit mb-5 mt-5">
            <Banner className="rounded-xl">
                <Detail className="-mt-2">
                    <H3>Benvenuto!</H3>
                    Se avevi un account su moduli.maggioli.it, puoi registrarti sul nuovo portale utilizzando la stessa email
                    e continuare esercitarti riscattando il codice dei volumi acquistati. <br/>
                    Per ulteriori informazioni <strong onClick={() => onClickGuide()} className="cursor-pointer">clicca qui</strong>. <br/>
                    Nota: sarà possibile esercitarsi solo nelle prove per cui è stato acquistato il relativo volume e riscattato il codice.
                </Detail>
            </Banner>
        </div>
    )
}