import { 
	Banner, 
	Detail, 
	Grid, 
	H3,
	InputText,
	Tag, 
	Row,
} from '@maggioli-design-system/react'

import '../../pages/Home/Home.css' 
import QuizCard from '../QuizCard/QuizCard'
import { ReactElement, useEffect, useState, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { getFree, getJustPublished } from '../../services/TestService'
import { ILoader } from '../../interfaces/ILoader';
import { ITest } from '../../interfaces/ITest'
import { getCookie, scrollTop, setCookie } from '../../utils/Functions'
import { IFavManager } from '../../interfaces/IFavManager'
import QuickSearch from '../QuickSearch/QuickSearch'
import { getBookCovers } from '../../services/ApproService'
import BannerModuli from '../BannerModuli/BannerModuli'

interface Props {
	handleLoader: ILoader,
    favManager: IFavManager,
}

interface ParamTypes {
	page: string,
}

export default function QuizList({ handleLoader, favManager }: Props): ReactElement {

    const RESULTS: number = 10;
	const { page } = useParams<ParamTypes>();
	const history = useHistory();
    const URL: string = history.location.pathname.split('/')[1];

	const [frequentWords, setFrequentWords] = useState<string[]>([]);
	const [tests, setTests] = useState<ITest[]>([]);
	const [endResults, setEndResults] = useState<boolean>(false);
	const [text, setText] = useState<string>('');
	const [firstLoad, setFirstLoad] = useState<boolean>(true);
	const [currentPage, setCurrentPage] = useState<number>(page === undefined ? 1 : parseInt(page));
	const [notFound, setNotFound] = useState<boolean>(false);
	const [freeTests, setFreeTests] = useState<ITest[]>([]);
	const [searchfreeTests, setSearchFreeTests] = useState<string>('');
	const [freeResults, setFreeResults] = useState<number>(RESULTS);

    const [writing, setWriting] = useState<boolean>(false);
    const justFree: boolean = history.location.pathname.includes('free');
    let timeID = useRef<any>(null);
    let time = useRef<number>(0);

	useEffect(() => {
		frequentWords.length > 0 && setCookie('freqwords', JSON.stringify(frequentWords.slice(0, 10)))
	}, [frequentWords])

	useEffect(() => {
		if(firstLoad) {
			return;
		}

		setWriting(true);
		manageTimer();    
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [text])

	useEffect(() => {
        if (writing) {
            return;
        }
        searchTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writing])

	useEffect(() => {
		justFree && freeTests.length === 0 && loadFreeTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [justFree])

	const manageTimer = () => {
        const timeSearch: number = 500;
        if (timeID.current === null) {
            timeID.current = setInterval(function() {
                if (time.current < timeSearch) {
                    time.current = time.current + timeSearch;
                } else {
                    time.current = 0;
                    setWriting(false);
                    clearInterval(timeID.current);
                    timeID.current = null;
                }
            }, timeSearch);
        } else {
			time.current = 0;
		}
    }

	const loadFreeTests = async () => {
		handleLoader.setLoaderVisible(true);
		const res = await getFree();
		const data = await res.json();
		handleLoader.setLoaderVisible(false);
		if (data.status) {
			setFreeTests(data.data);
		}
	}

	const searchTests = async () => {
		handleLoader.setLoaderVisible(true);
		if (firstLoad) {
			setFirstLoad(false);

			const freqWords: string[] = JSON.parse(getCookie('freqwords'));
			if (freqWords !== null) {
				setFrequentWords(freqWords.reverse().slice(0, 20));
			}
		}
		const res = await getJustPublished(1, currentPage * RESULTS, text);
		if (!res.ok) {
			handleLoader.setLoaderVisible(false);
			return;
		}

		const data = await res.json();		
		if (data.data.length > 0) {
			updateFrequentWords();

			const ress = await getBookCovers(data.data.map(b => b.ean).join(','));
			const temp = ress.status ? ress.data : [];
			setTests(data.data.map(t => {
				return {
					...t,
					image: temp.filter(b => b.isbn === t.ean).length > 0 ? temp.filter(b => b.isbn === t.ean).pop().guid : ''
				}
			}));
		}
		handleLoader.setLoaderVisible(false);

		setEndResults(data.data.length === 0 || data.data.length % RESULTS > 0);
		setNotFound(data.data.length === 0);
	}

	const loadNextTests = async () => {
		const res = await getJustPublished(currentPage + 1, RESULTS, text);
		if (!res.ok) {
			return;
		}
		const data = await res.json();
		setTests([ ...tests, ...data.data.sort((a, b) => a.ean.localeCompare(b.ean)) ]);

		const ress = await getBookCovers(data.data.map(b => b.ean).join(','));
		const temp = ress.status ? ress.data : [];

		handleLoader.setLoaderVisible(false);
		setTests([ 
			...tests, 
			...data.data.map(t => {
				return {
					...t,
					image: temp.filter(b => b.isbn === t.ean).length > 0 ? temp.filter(b => b.isbn === t.ean).pop().guid : ''
				}
			}),
		]);

		if (data.data.length % RESULTS > 0 || data.data.length === 0) { 
			setEndResults(true);
		} else {
			const newURL: string = URL === 'user-home' ? URL : 'home';
			setCurrentPage(currentPage + 1);
			history.push(`/${newURL}/${currentPage + 1}`)
		}
	}

	const testsFields = () => {
		if (tests.length === 0) {
			return <Banner status="warning" className="rounded-2xl">
				<Detail>
					Non sono state trovate prove a cui puoi accedere. <br />
					Riscatta un codice per accedere alla prova allegata.
				</Detail>
			</Banner>
		}

		return tests.map((e, i) => <QuizCard key={i} favManager={favManager} data={e}/>)
	}

	const freeTestsFields = () => {

		const tempFreeTests: ITest[] = freeTests.filter(e => e.title.toLowerCase().includes(searchfreeTests.toLowerCase()));
		if (tempFreeTests.length === 0) {
			return <Banner status="warning" className="rounded-2xl">
				<Detail>
					Non sono state trovate prove gratuite
				</Detail>
			</Banner>
		}

		return tempFreeTests.slice(0, freeResults).map((e, i) => <QuizCard key={i} favManager={favManager} data={e}/>)
	}

	const updateFrequentWords = () => {
		if (text.length <= 2) {
			return;
		}
		const words: string[] = text.split(' ');
		const temp: string[] = frequentWords;
		words.filter(e => e.length > 2).forEach(e => {
			!temp.includes(e) && parseWord(e) && setFrequentWords([ ...temp, e ]);
		});
	}

	const parseWord = (word): boolean => {
		let index = null;
		frequentWords.filter(e => e.length > 2).forEach((e, i) => { if (index === null && word.includes(e)) { index = i } });
		if (index !== null) {
			const firstPart: string[] = frequentWords.slice(0, index);
			const secondPart: string[] = frequentWords.slice(index + 1, frequentWords.length);
			setFrequentWords([ ...firstPart, word, ...secondPart ]);
			return false;
		}
		frequentWords.filter(e => e.length > 2).forEach((e, i) => { if (index === null && e.includes(word)) { index = i } });
		return index === null;
	}

	const bottomButtons = () => {
		const scrollUpButton = <div>
									<Tag className="cursor-pointer" chip={true} icon="navigation-hide" onClick={() => scrollTop()}>
										<b>Torna su</b>
									</Tag>
								</div>
		const loadMoreButton = <div className="-mt-8">
									<Tag onClick={() => justFree ? setFreeResults(freeResults + RESULTS) : loadNextTests()} className="bg-adjust-tone-16 cursor-pointer" chip={true}>
										<b>Carica più risultati...</b>
									</Tag>
								</div> 
		const checkEndResults: boolean = justFree ? freeResults >= freeTests.length : endResults;
		return checkEndResults ? scrollUpButton : loadMoreButton;
	}

	const IS_LOGGED: boolean =  getCookie("loggedUser") !== null;
    return <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
		{ !IS_LOGGED && <BannerModuli/> }
		<Grid className="gap-10 view-limit grid-tmpl-home mobile:grid-cols-1">
			
			<QuickSearch text={text} setText={setText} frequentWords={frequentWords} />
			
			<Grid rows="fit-vertically">
				{ notFound &&
				<Banner className="rounded-xl">
					<Detail>Non è stato trovato alcun elemento.</Detail>
				</Banner>}
				
				{ justFree && 
				<Row lastChild="to-right">
					<H3>Prove gratuite</H3>
					<InputText placeholder="Cerca..." className="w-96" value={searchfreeTests} onChange={e => setSearchFreeTests(e.target.value)}/>
				</Row> }
				<Grid template="auto-fit-large" className="gap-10 mobile:gap-4" rows="fit-vertically">
					{ justFree ? freeTestsFields() : testsFields() }
					{ (tests.length === 1 || freeTests.length === 1) && <div></div> }
				</Grid>

				<div></div>

				{ bottomButtons() }
			</Grid>

		</Grid>
	</div>
}
