import { useEffect, useState } from "react";
import { Caption, Row } from "@maggioli-design-system/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Footer from "./components/Footer";
import Header from "./components/Header";
import { ILoader } from "./interfaces/ILoader";
import Home from "./pages/Home";
import HomeLogged from "./pages/HomeLogged";
import LoginPage from "./pages/LoginPage";
import NotFound from "./pages/NotFound/NotFound";
import QuizPage from "./pages/QuizPage";
import Cookies from "./pages/Cookies/Cookies";
import Contact from "./pages/Contact/Contact";
import QuizPlay from "./pages/QuizPlay/QuizPlay";
import QuizResults from "./pages/QuizResults/QuizResults";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import Activate from "./pages/Activate/Activate";
import QuestionPreview from "./pages/QuestionPreview.tsx/QuestionPreview";
import Help from "./pages/Help/Help";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import ResetPassword from "./pages/ResetPassword /ResetPassword";
import { getCookie } from "./utils/Functions";

const App: React.FC = (): JSX.Element => {
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);
    const [isWorker, setIsWorker] = useState<boolean>(false);
    const IS_LOGGED: boolean = getCookie("loggedUser") !== null;
    const currentYear: number = new Date().getFullYear();

    const handleLoader: ILoader = {
        loaderVisible: loaderVisible,
        setLoaderVisible: (v) => setLoaderVisible(v),
    };

    useEffect(() => {
        checkUserAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkUserAccess = async () => {
        if (!IS_LOGGED) {
            return;
        }
        setLoaderVisible(true);
        const loggedUser = JSON.parse(getCookie("loggedUser"));
        const res = await fetch(
            `https://approfondimenti.maggiolicloud.it/core/auth/api/v2/get/users/canAccess/${loggedUser.id}/${loggedUser.email}`,
            {
                method: "GET",
                headers: {
                    "Authorization": "Basic " + Buffer.from(`api:Wtb9N2GSLLwuZN69`, "utf-8").toString("base64"),
                    "Content-Type": "application/json",
                },
            },
        );

        setLoaderVisible(false);
        if (!res.ok) {
            return false;
        }
        const data = await res.json();
        setIsWorker(data.status);
    };

    return (
        <Router>
            <Header isWorker={isWorker} />
            {IS_LOGGED ? (
                <Switch>
                    <Route exact path="/">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/guida">
                        <Help handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/free">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/register">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/login">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/home/:page">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/user-home/:page">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/quiz/:id">
                        <QuizPage handleLoader={handleLoader} isWorker={isWorker} />
                    </Route>
                    <Route exact path="/quiz/:simulationID/:flatID/play">
                        <QuizPlay handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/question/preview/:id">
                        <QuestionPreview handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/quiz/:id/results">
                        <QuizResults handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/informativa-sui-cookies">
                        <Cookies handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/contact">
                        <Contact handleLoader={handleLoader} />
                    </Route>

                    <Route exact path="/:passCode">
                        <HomeLogged handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/quiz/:id/:passCode">
                        <QuizPage handleLoader={handleLoader} isWorker={isWorker} />
                    </Route>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            ) : (
                <Switch>
                    <Route exact path="/">
                        <Home handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/recover">
                        <RecoverPassword handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/resetPassword/:code">
                        <ResetPassword handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/guida">
                        <Help handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/activate/:passCode">
                        <Activate />
                    </Route>
                    <Route exact path="/register">
                        <RegisterPage handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/register/free">
                        <RegisterPage handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/login">
                        <LoginPage />
                    </Route>
                    <Route exact path="/quiz/:id">
                        <QuizPage handleLoader={handleLoader} isWorker={isWorker} />
                    </Route>
                    <Route exact path="/home/:page">
                        <Home handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/informativa-sui-cookies">
                        <Cookies handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/contact">
                        <Contact handleLoader={handleLoader} />
                    </Route>

                    <Route exact path="/:passCode">
                        <Home handleLoader={handleLoader} />
                    </Route>
                    <Route exact path="/quiz/:id/:passCode">
                        <QuizPage handleLoader={handleLoader} isWorker={isWorker} />
                    </Route>
                    <Route>
                        <NotFound />
                    </Route>
                </Switch>
            )}
            <Footer loaderVisible={loaderVisible} />

            <div style={{ color: "white" }} className="bg-brand-maggioli-04">
                <Row className="view-limit p-4">
                    <Caption className="m-auto">
                        Copyright 2003-{currentYear} Maggioli Spa - P.Iva 02066400405 - Iscritta al R.E.A. di Rimini al n. 219107
                    </Caption>
                </Row>
            </div>
        </Router>
    );
};

export default App;
