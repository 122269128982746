import Connection from "../utils/Connection";
import { EXPRESS_URL } from "../utils/Macros";

export async function getForms(token: string, callback: Function) {

    const data = { token: token };

    await fetch('https://privacy.maggiolicloud.it/get/form', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        callback(data);
    })
    .catch(error => console.log(error))
}

export async function savePrivacyData(payload: any, actionURL: string, callback: Function) {

    await fetch(actionURL, {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
        callback(data);
    })
    .catch(error => console.log(error))
}

export async function sendDataToSubscriptions(data: any, callback: Function) {

    const headers = new Headers();
    const user: string = 'api';
    const password: string = '4BVFp>AK{S>e=mJ';

    headers.set('Authorization', 'Basic ' + Buffer.from(`${user}:${password}`, 'utf-8').toString('base64'));
    headers.set('Content-Type', 'application/json');
    
    await fetch('https://subscriptions.maggiolicloud.it/email', {
        method: 'POST', 
        headers: headers,
        body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
        callback(data);
    })
    .catch(error => console.log(error))
}

export async function sendDataNoPrivacy(data: any) {
    return await Connection.post(`${EXPRESS_URL}email`, data)
}