import {
    Button,
    Grid,
    Icon,
    LabelDetail,
} from '@maggioli-design-system/react'
import clsx from 'clsx'
import { verdict, verdictText } from '../../functions/verdict'
import { useHistory } from 'react-router-dom';

import { register, format } from 'timeago.js';
import italian from 'timeago.js/lib/lang/it';
import { deleteCustomerHistory } from '../../services/CustomerHistoryService';
register('it', italian)

const icon = {
    good: 'emoticon-happy',
    almost: 'emoticon-neutral',
    bad: 'emoticon-sad',
}

const colors = {
    good: {
        background: 'bg-status-success-19',
        icon: 'text-status-success-09',
        text: 'text-status-success-04',
    },
    almost: {
        background: 'bg-status-warning-19',
        icon: 'text-status-warning-09',
        text: 'text-status-warning-04',
    },
    bad: {
        background: 'bg-status-error-19',
        icon: 'text-status-error-09',
        text: 'text-status-error-04',
    },
}

interface ISimulationResultPill {
    saveIndexSimulation?: Function,
    pointsTotal?: number,
    pointsMade?: number,
    pointsNeeded?: number,
    simulationDate?: string,
    simulationTitle?: string,
    testID: string,
    id: string,
    deleteUpdate: Function,
}

const SimulationResultPill: React.FC<ISimulationResultPill> = ({
    pointsTotal = 200,
    pointsNeeded = 180,
    pointsMade = 182,
    simulationTitle = 'Simulazione',
    simulationDate,
    testID,
    saveIndexSimulation,
    id,
    deleteUpdate = () => {}
}: ISimulationResultPill): JSX.Element => {

    const result = verdict(pointsMade, pointsNeeded);
    const { title } = verdictText(pointsMade, pointsNeeded);
    const history = useHistory();

    const goToSimulationResults = () => {
        if (saveIndexSimulation) {
            saveIndexSimulation();
        }
        history.push(`/quiz/${testID}/results`);
    }

    const onClickDelete = async () => {
        const res = await deleteCustomerHistory(id);
        if (!res.ok) {
            return;
        }
        deleteUpdate(id);
    }

    return <div className="flex gap-2 items-center flex-grow">
        <div className={clsx(
            'flex gap-2 py-2 pr-3 pl-2 mobile:pr-2 rounded-lg items-center',
            colors[result].background,
            colors[result].text,
        )}>
            <Icon name={icon[result]} className={colors[result].icon} />
            <LabelDetail className="mobile:hidden">{simulationTitle}: {title}</LabelDetail>
        </div>
        <Grid className="gap-1 flex-grow">
            <LabelDetail className="leading-none">{pointsMade} / {pointsTotal}</LabelDetail>
            <LabelDetail className="leading-none">{format(simulationDate, 'it')}</LabelDetail>
        </Grid>
        {/* <Button variant="secondary-outline" icon="action-replay"/> */}
        <Button variant="secondary-outline" icon="status-info" onClick={() => goToSimulationResults()} />
        <Button variant="error-outline" icon="crud-delete" onClick={() => onClickDelete()} />
    </div>
}

export default SimulationResultPill
