import {
  Bullet as BulletDS,
  Icon,
  Card,
  Grid,
  H5,
  Tag,
} from '@maggioli-design-system/react'

import './QuizCard.css'
import clsx from 'clsx'
import { onChangeFav } from '../../utils/Functions'
import { useHistory } from 'react-router-dom'
import { ITest } from '../../interfaces/ITest'
import { IFavManager } from '../../interfaces/IFavManager'

import { APPRO_IMAGE } from '../../utils/Macros'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Placeholder from '../../placeholder.png'
import { useEffect, useState } from 'react'

interface IQuizCard {
  preparationLevel?: string,
  simulationsDone?: string,
  data: ITest,
  favManager: IFavManager,
  isFavCard?: boolean,
}

const QuizCard: React.FC <IQuizCard> = ({
  preparationLevel,
  simulationsDone,
  data,
  favManager,
  isFavCard = false,
}: IQuizCard) : JSX.Element => {

  const history = useHistory();
  const fav: boolean = favManager.fav.filter(d => d.id === data.id).length > 0;
  const maxTitleLength: number = 100;
  const isFree: boolean = history.location.pathname.includes('free')
  const cardHeight: string = isFree ? 'h-32' : 'h-48';

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [])

  const onClickFav = (e, elem: ITest) => {
    e.preventDefault();
    e.stopPropagation();
    const temp = data.image !== '' ? { ...elem, image: data.image } : elem;
    onChangeFav(fav, temp);
    if (!fav) {
      favManager.setFav([ ...favManager.fav, elem ]);
    } else {
      favManager.setFav(favManager.fav.filter(e => e.id !== data.id));
    }
  }

  const updateWidth = () => {
    setWidth(window.innerWidth);
  }

  const image: string = data.image !== '' ? `${APPRO_IMAGE}${data.image}` : Placeholder;
  const favIconPosition: string = isFree ? 'left-80' : 'left-56';
  const favIconPositionMobile: string = isFree ? 'left-64' : 'left-52';

  return <div onClick={() => history.push(`/quiz/${data.id}`)}>
    <Card className={`quiz-card gap-0 p-0 overflow-hidden cursor-pointer shadow hover:shadow-lg ${cardHeight}`} >
      
      <Grid className="grid-cols-4">

        { !isFree && (width >= 1440 || (width < 882 && width > 768) || (width < 548 && width > 360) ) && 
        <div className='w-28 col-span-1 m-3'><LazyLoadImage className="rounded-lg shadow-lg" src={image}/></div> }

        <Grid className="px-8 pt-4 items-start quiz-card-infos col-span-3 relative">
          <H5 className="flex-grow max-mobile:ml-2">
            { data.title.slice(0, maxTitleLength) }
            { data.title.length > maxTitleLength && '...'}
          </H5>

          { isFree && 
          <div className="-mt-5"><Tag chip size="xsmall" className="bg-brand-maggioli-16">Gratuita</Tag></div> }
          { !isFree &&
          <Icon onClick={(e) => onClickFav(e, data)} name={fav ? 'data-favorite' : 'data-favorite-off' } size="large"
              className={`absolute ${favIconPosition} sm:${favIconPositionMobile} -top-1 z-9 ` + clsx(fav ? 'text-label-amaranth-07' : 'text-label-amaranth-11')}/> }
          <BulletDS value={`${data.num_simulations}`} variant="counter" className="flex-grow absolute top-36 left-8">
            Simulazioni disponibili
          </BulletDS>     
        </Grid>
      </Grid>
    </Card> 
  </div>
}

export default QuizCard
