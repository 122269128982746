import {
  Bullet as BulletDS,
  Button,
  ChecklistItem,
  Caption,
  Detail,
  Grid,
  H5,
  Hr,
  Tag,
  Row,
} from '@maggioli-design-system/react'

import { useHistory } from 'react-router-dom';
import clsx from 'clsx'
import { ISimulation } from '../../interfaces/ISimulation'
import DescriptionTip from '../DescriptionTip/DescriptionTip'
import { scorePerTheme, timeConvert } from '../../utils/Functions'

interface ISimulationDetails {
  className?: string,
  onClick?: Function,
  selected?: boolean,
  freeDone?: boolean,
  data: ISimulation,
}

const SimulationDetails: React.FC <ISimulationDetails> = ({
  className,
  onClick,
  data,
  selected = false,
  freeDone = false,
}: ISimulationDetails) : JSX.Element => {

	const history = useHistory();

  if (data === null) {
    return <div></div>;
  }

  const minScore: string = data.settings.data.min_score;
  const score: string = scorePerTheme(data.settings.data) ? `${minScore} / ${data.settings.data.themes.reduce((a, b) => a + (b.number * b.right), 0)}`
                        : `${minScore} / ${data.settings.data.right * data.settings.data.total_questions}`;

  const isDataBank: boolean = data.settings.data.data_bank !== 0;

  const daysLeft = () => {
    const today = new Date();
    const end = new Date(data.started);
    end.setMonth(end.getMonth() + 1);
    const diffTime = Math.abs(end.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  return <Grid className={clsx(
    className,
    'rounded-2xl gap-4 p-4',
    selected ? 'bg-status-info-19' : 'bg-adjust-tone-20'
    )}>
    <div className="flex items-center gap-4">
      <H5 className="flex-grow">{ data.title }</H5>
      { data.settings.data.free === '1' && <Tag chip size="xsmall" className="bg-brand-maggioli-16">Gratuita</Tag>}
    </div>
    <Grid className="rounded-xl bg-adjust-tone gap-0 p-4">
      <DescriptionTip fullWidth={true} typography="detail" className="text-adjust-tone-08" value={timeConvert(data.settings.data.time)}>Tempo disponibile</DescriptionTip>
      <DescriptionTip fullWidth={true} typography="detail" className="text-adjust-tone-08" value={score}>Punteggio minimo</DescriptionTip>
      <div className="flex gap-4 w-full mt-2 mobile:gap-2 mobile:mt-4 mobile:flex-wrap">
        <BulletDS value={data.settings.data.total_questions} variant="counter" className="flex-grow">Domande</BulletDS>
      </div>
    </Grid>
    <div className="flex items-center gap-4">
      <Grid className="gap-0 flex-grow">
        <ChecklistItem checked={isDataBank}>Banca dati ufficiale</ChecklistItem>
        <ChecklistItem checked={!isDataBank}>Archivio Maggioli</ChecklistItem>
      </Grid>
      { onClick && !freeDone ?
      <Button onClick={() => onClick(data.id)} className={clsx(
        'flex-shrink-0',
        selected ? 'bg-status-info-10' : 'bg-brand-maggioli-04' )}>
          { selected ? 'Selezionato' : 'Nuova'}
      </Button> : <Button variant="error" disabled={true}>Simulazioni gratuite esaurite</Button> }
    </div>
    <Hr spacing="xxsmall"/>
    { data.paused !== '' &&
    <Row lastChild="to-right">
      <div>
        <Detail>Simulazione in sospeso</Detail>
        <Caption>({daysLeft()}gg rimanenti)</Caption>
      </div>
      <Button onClick={() => history.push(`/quiz/${data.id}/${data.paused}/play`)} className={'bg-brand-maggioli-09 justify-content-end' }>
          Continua 
      </Button>
    </Row> }
  </Grid>
}

export default SimulationDetails
