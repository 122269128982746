import {
    Banner,
    Button,
    Detail,
    Grid,
    H4,
    H5,
    H6,
    Hr,
    Icon,
    Table,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
    Row,
    Select,
    Switch,
} from '@maggioli-design-system/react' 
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IDatabank } from '../../interfaces/IDatabank'
import { ISimulation } from '../../interfaces/ISimulation'
import { checkProgress } from '../../services/CustomerRecordService'
import { getDataBank } from '../../services/DataBankService'
import { createFlatSimulation } from '../../services/SimulationService'
import { getQuestionsCountInThemeMulti } from '../../services/ThemeService'
import { getCookie, scorePerTheme, timeConvert } from '../../utils/Functions'
import InputNumber from '../InputNumber/InputNumber'
import './SimulationConfig.css'

interface ITitle {
    className?: string,
    icon?: string,
    children?: React.ReactChild,
}

const Title: React.FC<ITitle> = ({
    className,
    children,
    icon = 'database',
}: ITitle): JSX.Element => {
    return <div className={clsx('flex gap-2', className)}>
        <Icon name={icon} />
        <H6>{children}</H6>
    </div>
}

interface ISimulationConfig {
    className?: string,
    data: ISimulation,
    testID: string,
    custom: {
        customConfig: boolean,
        setCustomConfig: Function
    }
}

export interface IThemeInSimulation {
    id: string,
    empty: number,
    right: number,
    wrong: number,
    title: string,
}

const SimulationConfig: React.FC<ISimulationConfig> = ({
    className,
    data,
    custom,
    testID,
}: ISimulationConfig): JSX.Element => {

    const MAX_TIME: number = 300;
    const SECONDS_OPTIONS: number[] = [2, 3, 4, 5];

    const history = useHistory();

    const [seconds, setSeconds] = useState<number>(0); 
    const [noStop, setNoStop] = useState<boolean>(false);
    const [skipQuest, setSkipQuest] = useState<boolean>(true);
    const [showRightAns, setShowRightAns] = useState<boolean>(false);
    const [minutes, setMinutes] = useState<number>(60);
    const [questionsPerTheme, setQuestionsPerTheme] = useState<number[]>([]);
    const [maxQuestionsPerTheme, setMaxQuestionsPerTheme] = useState<number[]>([]);
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [themes, setThemes] = useState<boolean[]>([]);
    const [errorNumQuest, setErrorNumQuest] = useState<string>('');
    const [minScore, setMinScore] = useState<number>(0);
    const [dataBank, setDataBank] = useState<IDatabank>(null);
    const [singleTheme, setSingleTheme] = useState<boolean>(false);
    const [selectedThemeID, setSelectedThemeID] = useState<number>(0);
    const [ratio, setRatio] = useState<number>(0);

    useEffect(() => {
        if (data === null) {
            return;
        }
        setQuestionsPerTheme(data.settings.data.themes.map((e => e.number)));
        setTotalQuestions(data.settings.data.total_questions);
        setMinutes(data.settings.data.time);
        setMinScore(data.settings.data.min_score);
        setThemes(data.settings.data.themes.map(() => true));
        getTotQuestionsForThemes();  
        loadDataBank(data.settings.data.data_bank);
        // getOtherUsersProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        singleTheme && custom.setCustomConfig(false);
        singleTheme && setMinScore(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleTheme])

    useEffect(() => {
        custom.customConfig && setSingleTheme(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custom.customConfig])

    useEffect(() => {
        if (data === null) {
            return;
        }
        if (singleTheme) {
            setMinScore(Math.round((maxScoreSingleTheme) * ratio * 10) / 10)
            return;
        }

        const temp = data.settings.data;
        let score: number = 0;
        if (!custom.customConfig) {
            score = temp.right === 0 ? temp.themes.reduce((a, b) => a + (b.number * b.right), 0) : temp.right * temp.total_questions;
            setMinScore(Math.round((score) * ratio * 10) / 10);
            return;
        }
        score = temp.right === 0 ? temp.themes.map((t, i) => t.right * questionsPerTheme[i]).filter(q => !isNaN(q)).reduce((a, b)  => a + b, 0)
                                : questionsPerTheme.filter(q => !isNaN(q)).reduce((a, b)  => a + b, 0) * temp.right;
        setMinScore(Math.round((score) * ratio * 10) / 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsPerTheme])

    // Stile informativa diritto d'autore
    const customStyle = {
        height: custom.customConfig || singleTheme ? '100%' : '0px',
        opacity: custom.customConfig || singleTheme ? '100%' : '0%',
        transition: 'all 0.3s ease-out',
        overflow: 'hidden'
    }

    const getTotQuestionsForThemes = async () => {
        const themes = data.settings.data.themes;
        const res = await getQuestionsCountInThemeMulti(themes.map(t => t.id).join(','));
        if (!res.ok) {
            return;
        }
        const dataMulti = await res.json();
        if (!dataMulti.status) {
            return;
        }
        setMaxQuestionsPerTheme(dataMulti.data);
    }

    const loadDataBank = async (databankID: number) => {
        if (databankID === 0) {
            setDataBank(null);
            return;
        }
        const res = await getDataBank(databankID.toString());
        if (!res.ok) {
            return;
        }
        const data = await res.json()
        setDataBank(data.data);
    }

    const showError = (text: string) => {
        setErrorNumQuest(text);
        window.setTimeout(() => setErrorNumQuest(''), 3000);
    }

    const onClickStart = async () => {

        if (minScore.toString() === '') {
            showError('Inserire un punteggio minimo valido');
            return;
        }

        if (singleTheme && selectedThemeID === 0) {
            showError('Scegliere la materia in cui ci si vuole esercitare');
            return;
        }

        if (singleTheme && (singleThemeQuestions === 0 || isNaN(singleThemeQuestions))) {
            showError('Scegliere almeno una domanda');
            return;
        }

        if (custom.customConfig && questionsPerTheme.filter(q => isNaN(q)).length > 0) {
            showError('Inserire 0 per le materie che si desidera escludere');
            return;
        }

        if ((custom.customConfig || singleTheme) && (minutes.toString() === '' || minutes.toString() === '0')) {
            showError('Inserire almeno 1 minuto come durata della prova');
            return;
        }
    
        const res = await checkProgress(data.id, JSON.parse(getCookie("loggedUser")).id);
        const resData = await res.json();
        startSimulation(resData.data);
    }

    const startSimulation = async (flatID: string) => {

        const tempThemes = !singleTheme ? 
        data.settings.data.themes.map((q: IThemeInSimulation, i: number) => { 
            return { id: q.id, title: q.title, number: questionsPerTheme[i], right: q.right, empty: q.empty, wrong: q.wrong }
        })
        : 
        data.settings.data.themes.map((q: IThemeInSimulation, i: number) => { 
            if (parseInt(q.id) === selectedThemeID) {
                return { id: q.id, title: q.title, number: questionsPerTheme[i], right: q.right, empty: q.empty, wrong: q.wrong }
            }
            return { id: q.id, title: q.title, number: 0, right: q.right, empty: q.empty, wrong: q.wrong }
        });

        const tempTotalQuestions: number = !singleTheme ? data.settings.data.data_bank === 0 ? questionsPerTheme.reduce((a, b) => a + b ) : totalQuestions 
                                    : singleThemeQuestions;

        const customData = {
            skip_questions: skipQuest,
            show_right: showRightAns,
            no_stop: noStop,
            auto_skip: seconds,
        };
        const simulationObject = {
            ...data,
            title: !singleTheme ? data.title : `${data.settings.data.themes.filter(t => parseInt(t.id) === selectedThemeID).pop().title} - ${data.title}`,
            customer_id: JSON.parse(getCookie('loggedUser')).id,
            old_flat_id: flatID !== '' ? flatID : null,
            custom: custom.customConfig || singleTheme ? customData : null,
            test_id: testID,
            settings: { 
                ...data.settings,
                data: {
                    ...data.settings.data,
                    time: custom.customConfig || singleTheme ? minutes : data.settings.data.time,
                    min_score: singleTheme ? minScore > maxScoreSingleTheme ? maxScoreSingleTheme : minScore : minScore,
                    themes: tempThemes,
                    total_questions: tempTotalQuestions,
                }
            }, 
        };

        const res = await createFlatSimulation(simulationObject);
        if (!res.ok) {
            return;
        }
        const resData = await res.json();
        if (resData.status) {
            history.push(`/quiz/${data.id}/${resData.status}/play`);
        }
    }

    const submitButton = <Button onClick={onClickStart} type="submit">Inizia simulazione</Button>

    if (data === null) {
        return <div></div>;
    }

    const themeFields = () => {
        if (data.settings.data.data_bank !== 0) {
            return <></>;
        }

        const differentScore = scorePerTheme(data.settings.data);
        return <div className="">
            <H4>Materie / Categorie</H4>
            { data.settings.data.themes.length > 0 ?
            <Table theme="alternate">
                <TableHeader>
                    <TableHeaderCell>Nome</TableHeaderCell>
                    <TableHeaderCell>N. domande</TableHeaderCell>
                    <TableHeaderCell>Corretta</TableHeaderCell>
                    <TableHeaderCell>Non data</TableHeaderCell>
                    <TableHeaderCell>Errata</TableHeaderCell>
                </TableHeader>

                <TableBody>
                    {data.settings.data.themes.map((e, i) => {
                        return <TableRow key={i}>
                            <TableCell>{e.title}</TableCell>
                            <TableCell><b>{e.number}</b></TableCell>
                            <TableCell><b>{differentScore ? e.right : data.settings.data.right}</b></TableCell>
                            <TableCell><b>{differentScore ? e.empty : data.settings.data.empty}</b></TableCell>
                            <TableCell><b>{differentScore ? e.wrong : data.settings.data.wrong}</b></TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            :
            <Banner className="mt-3" status="warning">
                <Detail>Non sono indicate materie in questa simulazione.</Detail>
            </Banner> }
        </div>
    }

    const dataBankFields = () => {
        if (dataBank === null) {
            return <></>;
        }

        return <div className="mt-5">
            <H4>Banca dati ufficiale</H4>
            <label className="mt-3">{dataBank.title}</label>
        </div>
    }

    const changeNumQuestsPerTheme = (index: number, newValue: number) => {
        if (newValue.toString() === '') {
            return;
        }
        const val: number = newValue > maxQuestionsPerTheme[index] ? maxQuestionsPerTheme[index] : newValue;
        const firstPart: number[] = questionsPerTheme.slice(0, index);
        const secondPart: number[] = questionsPerTheme.slice(index + 1, themes.length);
        setQuestionsPerTheme([...firstPart, val, ...secondPart]);
    }

    const changeTotalQuestions = (newValue: number) => {
        if (newValue.toString() === '') {
            return;
        }
        const val: number = newValue > data.settings.data.total_questions ? data.settings.data.total_questions : newValue;
        setTotalQuestions(val);
    }

    const setTotalTime = (newValue: number) => {
        const val: number = newValue > MAX_TIME ? MAX_TIME : newValue;
        setMinutes(val);
    }

    const totalScore = () => {
        const temp = data.settings.data;
        if (!custom.customConfig) {
            const score: number = temp.right === 0 ? temp.themes.reduce((a, b) => a + (b.number * b.right), 0) : temp.right * temp.total_questions;
            if (ratio === 0 && minScore !== 0) {
                setRatio(minScore / score);
            }
            return score;
        }
        return temp.right === 0 ? temp.themes.map((t, i) => t.right * questionsPerTheme[i]).filter(q => !isNaN(q)).reduce((a, b)  => a + b, 0)
                                : questionsPerTheme.filter(q => !isNaN(q)).reduce((a, b)  => a + b, 0) * temp.right;
    }

    const suggestedMinScoreRatio: number = Math.floor(ratio * 100);
    const singleThemeIndex: number = data.settings.data.themes.map(t => parseInt(t.id)).indexOf(selectedThemeID);
    const maxSingleThemeQuestions: number = singleThemeIndex >= 0 ? maxQuestionsPerTheme[singleThemeIndex] : 0;
    const singleThemeQuestions: number = singleThemeIndex >= 0 ? questionsPerTheme[singleThemeIndex] : 0;
    const onChangeSingleThemeQuestions: Function = singleThemeIndex >= 0 ? (e) => changeNumQuestsPerTheme(singleThemeIndex, parseInt(e.target.value)) : () => {};
    const maxScoreSingleTheme: number = singleThemeIndex >= 0 ? 
                                            data.settings.data.right === 0 ? data.settings.data.themes.filter(t => parseInt(t.id) === selectedThemeID).right * singleThemeQuestions
                                            : data.settings.data.right * singleThemeQuestions  : 0;

    return <Grid className={clsx(className, 'gap-8')}>
        <Grid className="gap-2">
            <H5>Opzioni simulazione "{data.title}"</H5>
            <Detail className="text-adjust-tone-08 mb-4">
                <Detail>Durante la prova è possibile mettere in pausa il tempo e riprendere l'esecuzione in un secondo momento.</Detail>
                <Detail>
                    Per <b>passare da una domanda all'altra</b> si possono utilizzare i <b>pulsanti a schermo</b>, oppure, se si sta utilizzando un computer,
                    le <b>frecce direzionali</b>. In alternativa sono disponibili i <b>tasti "a" e "d"</b> rispettivamente per andare  
                    <b> indietro e avanti</b> tra le domande.
                </Detail>
                <Detail>
                    <b>Nota:</b> le prove lasciate <b>in sospeso</b> saranno tenute in memoria <b>per un mese</b>, dopodiché verranno eliminate in automatico.
                </Detail> 
            </Detail>

            <Grid columns="2" gutter="xsmall">
                { data.settings.data.free !== '1' &&
                <Switch isChecked={custom.customConfig} onChange={() => custom.setCustomConfig(!custom.customConfig)}>
                    Simulazione personalizzata
                </Switch> }
                { data.settings.data.data_bank === 0 && data.settings.data.free !== '1' &&
                <Switch isChecked={singleTheme} onChange={() => setSingleTheme(!singleTheme)}>Simulazione su singola materia</Switch> }
                <div></div>
                {submitButton}
            </Grid>
        </Grid>

        <Hr className="bg-adjust-tone-18 -my-3" />

        {!custom.customConfig && !singleTheme &&
            <div>
                <H4>Impostazioni predefinite</H4>
                <Table theme="alternate">
                    <TableBody>
                        <TableRow>
                            <TableCell> Tempo a disposizione</TableCell>
                            <TableCell> <b>{timeConvert(data.settings.data.time)}</b></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> Domande totali</TableCell>
                            <TableCell> <b>{data.settings.data.total_questions}</b></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Punteggio minimo</TableCell>
                            <TableCell> <b>{data.settings.data.min_score} / {totalScore()}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Hr className="bg-adjust-tone-18 mb-4 mt-2" />

                {themeFields()}

                {dataBankFields()}
            </div>}

        <div style={customStyle}>
            <Grid>

                <Grid>
                    <Row>
                        <Title icon="timer-outline">Tempo</Title>
                    </Row>

                    <InputNumber max={300} min={0} name="minutes" value={minutes} label="Durata prova (minuti)" onChange={e => setTotalTime(e.target.value)}/>

                    <Select label="Dopo la risposta si passa alla domanda successiva" onChange={e => setSeconds(e.target.value)} value={seconds}>
                        <option value={0}>non passare alla prossima domanda</option>
                        <option value={1}>dopo 1 secondo</option>
                        {SECONDS_OPTIONS.map((e, i) => <option key={i} value={e}>dopo {e} secondi</option>)}
                    </Select>

                    <Switch isChecked={noStop} onChange={() => setNoStop(!noStop)}>
                        Non interrompere il test allo scadere del tempo
                    </Switch>
                </Grid>

                <Hr className="bg-adjust-tone-18 -mt-1"/>

                {data.settings.data.themes.length > 0 && questionsPerTheme.length > 0 && !singleTheme &&
                <Grid className="gap-8 -mt-6">
                            
                    <Row>
                        <Title icon="data-category">Domande per materia / categoria</Title>
                    </Row>
                    <InputNumber name={'min-score'} value={minScore} label={`Punteggio minimo suggerito (${suggestedMinScoreRatio}%)`}
                            onChange={e => setMinScore(e.target.value)} max={totalScore()} decimalStep={true}/>
                    <Grid columns="2" className="gap-8">
                        {data.settings.data.themes.map((e, i) =>
                        <Row key={i}>
                            <InputNumber max={maxQuestionsPerTheme[i]} min={0} name={e.title} value={questionsPerTheme[i]} label={`${e.title}`} 
                                    onChange={e => changeNumQuestsPerTheme(i, parseInt(e.target.value))}/>
                        </Row>)}
                    </Grid>
                </Grid> }

                {data.settings.data.themes.length > 0 && questionsPerTheme.length > 0 && !custom.customConfig &&
                <Grid className="gap-8 -mt-6">
                          
                    <Select label="Scegli materia" onChange={e => setSelectedThemeID(parseInt(e.target.value))} value={selectedThemeID}>
                        <option value={0}>...</option>
                        {data.settings.data.themes.map((e, i) => <option key={i} value={e.id}>{e.title}</option>)}
                    </Select>
                    <InputNumber max={maxSingleThemeQuestions} min={0} name='numero domande' value={singleThemeQuestions} label={`Numero domande`} 
                            onChange={onChangeSingleThemeQuestions}/>

                    <InputNumber name={'min-score'} value={minScore} label={`Punteggio minimo suggerito (${suggestedMinScoreRatio}%)`}
                            onChange={e => setMinScore(e.target.value)} max={maxScoreSingleTheme} decimalStep={true}/>
                </Grid> }

                {data.settings.data.data_bank > 0 &&
                <Grid className="gap-8 -my-6">   
                    <Title icon="rule">Banca dati ufficiale</Title>
                    <InputNumber max={data.settings.data.total_questions} min={0} value={totalQuestions} label="Domande totali"
                            onChange={e => changeTotalQuestions(parseInt(e.target.value))} name="bancadati"/>
                </Grid>}

                <Hr className="bg-adjust-tone-18 -mt-1"/> 
                    
                <Grid columns="2" className="-mt-4">
                    <Switch isChecked={showRightAns} onChange={() => setShowRightAns(!showRightAns)}>
                        Mostra risposte corrette
                    </Switch>
                    <Switch isChecked={skipQuest}  onChange={() => setSkipQuest(!skipQuest)}>
                        Possibile non rispondere
                    </Switch>
                </Grid>

                { errorNumQuest !== '' &&
                <Banner className="rounded-xl" status="error">
                    <Detail>{errorNumQuest}</Detail>
                </Banner> }
                {submitButton}

            </Grid>
        </div>

    </Grid>
}

export default SimulationConfig
