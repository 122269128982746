import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionsBar, ActionsBarItems, Button, H4, Header, HeaderLogo, HeaderMenu, Grid, Modal, Row } from "@maggioli-design-system/react";
import { useHistory } from "react-router-dom";
import { getCookie, onClickLogout, scrollTop } from "../../utils/Functions";
import RedeemCodeModal from "../RedeemCodeModal/RedeemCodeModal";
import Logo from "../../logo.png";

interface IProps {
    isWorker: boolean;
}

const HeaderQuiz = ({ isWorker }: IProps): JSX.Element => {
    const IS_LOGGED: boolean = getCookie("loggedUser") !== null;
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showRegister, setShowRegister] = useState<boolean>(false);

    useEffect(() => {
        setShowRegister(!IS_LOGGED && !history.location.pathname.includes("free") && !history.location.pathname.includes("quiz"));
        history.listen(() => {
            setShowRegister(!IS_LOGGED && !history.location.pathname.includes("free") && !history.location.pathname.includes("quiz"));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redeemCodeButton = (
        <Button variant="primary" onClick={() => setShowModal(true)}>
            Inserisci il codice d'accesso
        </Button>
    );
    const registerButton = (
        <Link to="/register">
            <Button width="fill" onClick={() => setVisible(false)}>
                Registrati
            </Button>
        </Link>
    );
    const loginButton = (
        <Link to="/login">
            <Button width="fill" variant="secondary-outline" onClick={() => setVisible(false)}>
                Accedi
            </Button>
        </Link>
    );
    const homeButton = (
        <Link to="/home/1">
            <Button
                width="fill"
                icon="dashboard"
                onClick={() => {
                    setVisible(false);
                    setConfirmLogout(false);
                    scrollTop();
                }}
            >
                Home
            </Button>
        </Link>
    );
    const backofficeButton = (
        <a href="/admin/" target="_blank" rel="noreferrer">
            <Button variant="primary-outline" width="fill" onClick={() => setConfirmLogout(false)}>
                Gestisci contenuti
            </Button>
        </a>
    );

    const logoutButton = (
        <Button
            onClick={() => {
                setVisible(false);
                setConfirmLogout(!confirmLogout);
            }}
            variant="error-outline"
            icon="action-logout"
        />
    );
    const helpLink = (
        <Link to="/guida">
            <Button variant="text" className="text-adjust-tone-04 px-2" horizontalPadding="none">
                Guida
            </Button>
        </Link>
    );

    const buttons = () => {
        return (
            <Row>
                {helpLink}
                {IS_LOGGED && !isWorker && redeemCodeButton}
                {!IS_LOGGED ? loginButton : homeButton}
                {isWorker && backofficeButton}
                {IS_LOGGED && logoutButton}
                {showRegister && registerButton}
            </Row>
        );
    };

    return (
        <Fragment>
            {confirmLogout && (
                <div
                    style={{
                        backgroundColor: "grey",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "repeat-y",
                        position: "fixed",
                        zIndex: 5,
                        opacity: "50%",
                    }}
                />
            )}

            {<RedeemCodeModal visible={showModal} setVisible={setShowModal} />}

            <ActionsBar className="" position="right" boxShadow="box" notVisible={!confirmLogout}>
                <H4>Sei sicuro di voler uscire?</H4>
                <ActionsBarItems>
                    <Button variant="primary-outline" icon="crud-cancel" onClick={() => setConfirmLogout(false)}>
                        Annulla
                    </Button>
                    <Button variant="error" icon="action-logout" onClick={() => onClickLogout()}>
                        Esci
                    </Button>
                </ActionsBarItems>
            </ActionsBar>

            <Header lastChild="to-right" className="shadow z-10">
                <Link to="/home/1">
                    <HeaderLogo
                        onClick={() => {
                            setConfirmLogout(false);
                            scrollTop();
                        }}
                        src={Logo}
                    />
                </Link>
                <HeaderMenu onClick={() => setVisible(!visible)} width="inline">
                    {buttons()}
                </HeaderMenu>
            </Header>

            <Modal className="z-20" visible={visible} onCancel={() => setVisible(false)} position="left" footer={false} contentOnly={true}>
                <Grid className="px-5 mt-6" gutter="small">
                    <HeaderLogo className="mx-auto" src={Logo} />

                    {helpLink}
                    {!IS_LOGGED ? loginButton : homeButton}
                    {IS_LOGGED && !isWorker && (
                        <Button
                            variant="primary"
                            onClick={() => {
                                setVisible(false);
                                setShowModal(true);
                            }}
                        >
                            Inserisci il codice d'accesso
                        </Button>
                    )}
                    <Link to="/free">
                        <Button variant="primary-outline" width="fill" onClick={() => setVisible(false)}>
                            Vedi prove gratuite
                        </Button>
                    </Link>
                    {isWorker && backofficeButton}
                    {IS_LOGGED && logoutButton}
                    {showRegister && registerButton}
                </Grid>
            </Modal>
        </Fragment>
    );
};

export default HeaderQuiz;
