export const BASE_URL = process.env.PUBLIC_URL;
export const EXPRESS_URL = BASE_URL + "/core/api/";
export const EXPRESS_GET = `${EXPRESS_URL}get/`;
export const EXPRESS_POST = `${EXPRESS_URL}post/`;
export const EXPRESS_PUT = `${EXPRESS_URL}put/`;
export const EXPRESS_DELETE = `${EXPRESS_URL}delete/`;

export const IMAGE_EXPRESS_URL = BASE_URL + "/core/api/image?";

export const RESULTS = [5, 15, 25, 50, 75];
export const RESULTS_MEDIA = [24, 32, 64, 128];

export const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const APPRO_REGISTER_URL: string = "https://approfondimenti.maggiolicloud.it/users/register";
export const APPRO_ACCOUNT_URL: string = "https://approfondimenti.maggiolicloud.it/account";
export const APPRO_URL: string = "https://approfondimenti.maggiolicloud.it/";
export const APPRO_IMAGE: string = "https://approfondimenti.maggiolicloud.it/uploads/";

export const ambiti: string[] = [
    "Forze di Polizia (polizia di stato, polizia penitenziaria, vigili del fuoco, guardia di finanza)",
    "Forze Armate (esercito, marina, aeronautica, carabinieri)",
    "Enti Locali e Territoriali (comuni, province, regioni…)",
    "Enti Centrali (Ministeri, Parlamento, INPS, Agenzie)",
    "Area Sanità",
    "Area Scuola",
    "Test universitari",
];

export const getCodedAmbito = (title: string) => {
    if (title === ambiti[2]) {
        return "concorsiEntiLoc";
    }
    if (title === ambiti[3]) {
        return "concorsiEntiPub";
    }
    if (title === ambiti[0] || title === ambiti[1]) {
        return "concorsiFFAA";
    }
    if (title === ambiti[4]) {
        return "concorsiSanitari";
    }
    if (title === ambiti[5]) {
        return "concorsiScuola";
    }
    if (title === ambiti[6]) {
        return "testUniversitari";
    }
    return "";
};

export const emailParams = {
    host: "smtp.sendgrid.net",
    port: 465,
    user: "apikey",
    password: "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",
};
