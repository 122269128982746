class Connection {

    username = 'api';
    password = 'b7uY3tKsciHi';
    headers = new Headers();
    
    constructor()
    {
        this.headers.set(
            'Authorization', 'Basic ' + btoa(`${this.username}:${this.password}`)
        )
        this.headers.set(
            'Content-Type', 'application/json'
        )
    }

    async post(url, param) {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: JSON.stringify(param)
        });
        return response;
    }

    async get(url) {
        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers
        });

        return response;
    }

    async put(url, param) {
        const response = await fetch(url, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: JSON.stringify(param)
        });
        return response;
    }

    async delete(url) {
        const response = await fetch(url, {
            method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
        });
        return response;
    }

    async upload(url, param) {
        this.headers.delete('Content-type');
        this.headers.set( 'Accept', "*/*" );
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: param
        });
        this.headers.set( 'Content-Type', 'application/json' );
        return response;
    }

    async uploadEdit(url, param) {
        this.headers.delete('Content-type');
        this.headers.set( 'Accept', "*/*" );
        const response = await fetch(url, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers,
            body: param
        });
        this.headers.set( 'Content-Type', 'application/json' );
        return response;
    }

    async checkEanAppro(url) {
        this.headers.set( 'Authorization', 'Basic ' + btoa(`api:Wtb9N2GSLLwuZN69`) );
        const response = await fetch(url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            headers: this.headers
        });
        this.headers.set( 'Authorization', 'Basic ' + btoa(`${this.username}:${this.password}`));
        return response;
    }
}

export default new Connection()
