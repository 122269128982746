import {
    Button,
    Grid,
    H4,
    Hr,
    Modal,
    Row,
} from '@maggioli-design-system/react'
import { ReactElement, useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import AnswerDescription from '../../components/AnswerDescription/AnswerDescription';
import CustomConfirmAlert from '../../components/CustomConfirmAlert/CustomConfirmAlert'
import QuestionRecap from '../../components/QuestionRecap/QuestionRecap';
import QuizAnswers from '../../components/QuizAnswers/QuizAnswers';
import QuizHeader from '../../components/QuizHeader/QuizHeader';
import QuizTopBar from '../../components/QuizTopBar'
import { IAnswer } from '../../interfaces/IAnswer';
import { IGivenAns } from '../../interfaces/IGivenAnswer';
import { ILoader } from '../../interfaces/ILoader';
import { IQuestion } from '../../interfaces/IQuestion';
import { ISimulation } from '../../interfaces/ISimulation';
import { editCustomerRecord, getProgress, saveCustomerRecord } from '../../services/CustomerRecordService';
import { endSimulation, getFlatSimulation } from '../../services/SimulationService';
import { autoNextQuestion, isCustom, noStop, showRight, skipQuestions } from '../../utils/CustomSimulationManager';
import { getCookie, getDifficulty, scrollTop } from '../../utils/Functions'
import './QuizPlay.css'

interface IOrderedQuestions {
    num: number,
    data: IQuestion,
}

interface ICustomerRecord {
    id: number,
    answer_id: string,
    question_id: string,
    right: string,
    time: string,
}

interface ITimer {
    time: number,
    questTime: number,
}

interface Props {
	handleLoader: ILoader
}

const QuizPlay = ({ handleLoader }: Props): JSX.Element => {

    const intervalTime: number = 1000;

    const history = useHistory();
    const { flatID, simulationID } = useParams<{ flatID: string, simulationID: string }>(); 

    const [simulation, setSimulation] = useState<ISimulation>(null);
    const [currentNumQuest, setCurrentNumQuest] = useState<number>(0);
    const [timer, setTimer] = useState<ITimer>({ time: intervalTime, questTime: intervalTime });
    const [givenAns, setGivenAns] = useState<IGivenAns[]>([]);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [questions, setQuestions] = useState<IOrderedQuestions[]>([]);
    const [endModal, setEndModal] = useState<boolean>(false);
    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [timeToNext, setTimeToNext] = useState<number>(0);
    const [showQuiz, setShowQuiz] = useState<boolean>(true);

    const wait: number = isCustom(simulation) ? parseInt(simulation.custom.auto_skip) : 0;
    const emptyAnswer = { answerID: null, right: null, time: 0, id: 0 };
    const timerID = useRef<any>(null);
    const isEnding = useRef<boolean>(false);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            loadFlatSimulation(); 
            window.addEventListener("beforeunload", () => {});
        }

        return () => {
            onClickPause();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeydown);

        return () => {
            window.removeEventListener("keydown", handleKeydown)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNumQuest, showQuiz, givenAns]);

    useEffect(() => {
        if (simulation !== null && timer.time / 60000 >= parseInt(simulation.settings.data.time) && !noStop(simulation)) {
            stopSimulation();
            return;
        }

        if (timer.time % 5000 === 0) {
            saveAnswerStatus();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNumQuest, givenAns, timer])

    const handleKeydown = (e) => {
        switch(e.key) {
            case "a": 
                onClickPrev();
                break;
            case "ArrowLeft": 
                onClickPrev();
                break;
            case "d": 
                onClickNext();
                break;
            case "ArrowRight": 
                onClickNext();
                break;
            default: break;
        }
    }

    const loadFlatSimulation = async () => {        
        handleLoader.setLoaderVisible(true);     
        const resSimu = await getFlatSimulation(flatID);
        if (!resSimu.ok) {
            return;
        }
        const dataSimu = await resSimu.json();
        if (!dataSimu) {
            handleLoader.setLoaderVisible(false);
            return;
        }

        setSimulation(dataSimu.simulation);
        setQuestions(dataSimu.simulation.questions.map((q, i) =>  { return { num: i + 1, data: q }}));
        
        const resCustomerRecord = await getProgress(simulationID, JSON.parse(getCookie("loggedUser")).id);
        const dataCustomerRecord = await resCustomerRecord.json();

        if (dataCustomerRecord.length > 0) {
            const timeElapsed: number = dataCustomerRecord.map(p => parseInt(p.time)).reduce((a, b) => a + b) * 1000;
            updateGivenAnswers(dataCustomerRecord, dataSimu.simulation.questions.map(q => q.id));
            setCurrentNumQuest(dataSimu.simulation.questions.map(q => q.id).indexOf(dataCustomerRecord[0].question_id));
            
            setTimer({ questTime: dataCustomerRecord[0].time * 1000, time: timeElapsed }) 
        } else {
            setGivenAns(dataSimu.simulation.questions.map(q => { return { questionID: q.id, ...emptyAnswer }}));
        }
        timerID.current = setInterval(tick, intervalTime);
        handleLoader.setLoaderVisible(false);
    }

    const saveAnswerStatus = async () => {
        if (givenAns.length === 0) {
            return;
        }

        const firstPart = givenAns.slice(0, currentNumQuest);
        const secondPart = givenAns.slice(currentNumQuest + 1, givenAns.length);
        const elem = givenAns[currentNumQuest];

        if (givenAns[currentNumQuest].id === 0) {
            const customerRecord = {
                simulation_id: simulationID,
                simulation_flat_id: flatID,
                customer_id: JSON.parse(getCookie("loggedUser")).id,
                question_id: currentQuestion.id,
                time: Math.round(timer.questTime / 1000),
            };
            const res = await saveCustomerRecord(customerRecord); 
            if (!res.ok) {
                return;
            }
            const dataRes = await res.json();
            if (!dataRes.status) {
                return;
            }
            setGivenAns([ ...firstPart, { ...elem, id: dataRes.status, time: timer.questTime } , ...secondPart ]);
        } else {
            editCustomerRecord(givenAns[currentNumQuest].id, { time: Math.round(timer.questTime / 1000)})
        }
    }

    const updateGivenAnswers = (customerRecords: ICustomerRecord[], questionsID: string[]) => {
        const tmpGivenAnswers: IGivenAns[] = [];
        questionsID.forEach(e => {
            const index: number = customerRecords.map(c => c.question_id).indexOf(e);
            if (index !== -1) {
                const data = customerRecords[index];
                tmpGivenAnswers.push({ questionID: e, right: data.right === '1', answerID: data.answer_id, time: parseInt(data.time) * 1000, id: data.id })
            } else {
                tmpGivenAnswers.push({ questionID: e, ...emptyAnswer });
            }
        });
        setGivenAns(tmpGivenAnswers);
    }

    const tick = () => {
        setTimer(p => ({ ...p, time: p.time + intervalTime, questTime: p.questTime + intervalTime }));
    }

    const updateQuestionsTime = () => {
        const firstPart: IGivenAns[] = givenAns.slice(0, currentNumQuest);
        const secondPart: IGivenAns[] = givenAns.slice(currentNumQuest + 1, givenAns.length);
        const elem: IGivenAns = { ...givenAns[currentNumQuest], time: timer.questTime };
        const finalData: IGivenAns[] = [ ...firstPart, elem, ...secondPart ];
        setGivenAns(finalData);
        return finalData;
    }

    const onClickPause = () => { 
        if (timerID.current !== null) {
            clearInterval(timerID.current);
            timerID.current = null;
            setShowQuiz(false);
        } else {
            timerID.current = setInterval(tick, intervalTime);
            setShowQuiz(true);
        }
    }

    const onClickNext = () => {
        if (currentNumQuest === questions.length - 1 || !showQuiz) {
            return;
        }
        scrollTop(); 
        setTimeToNext(0);
        updateQuestionsTime();
        setCurrentNumQuest(currentNumQuest + 1);
        setTimer(p  => ({ ...p, questTime: givenAns[currentNumQuest + 1].time }));
    }

    const onClickPrev = () => {
        if (currentNumQuest === 0 || !showQuiz) {
            return;
        }
        scrollTop();
        setTimeToNext(0);
        updateQuestionsTime();
        setCurrentNumQuest(currentNumQuest - 1);
        setTimer(p  => ({ ...p, questTime: givenAns[currentNumQuest - 1].time }));
    }

    if (currentNumQuest < 0 || questions.length === 0) {
        return <div></div>;
    }

    if (autoNextQuestion(simulation) && timeToNext !== 0 && currentNumQuest < questions.length - 1  && (timer.time - timeToNext) / 1000 >= wait) {
        onClickNext();
    }
    
    const currentQuestion = questions[currentNumQuest].data;
    const numGivenAnswers: number = givenAns.filter(e => e.answerID !== null).length;

    const prevQuestButon: ReactElement = <Button icon="paginator-previous" variant="primary-outline"
                                                onClick={() => onClickPrev()}>Precedente</Button>

    const nextQuestButton = () => { 
        const isAnswered: boolean = givenAns.filter(a => a.answerID !== null).map(a => a.questionID).includes(currentQuestion.id);
        const text: string = isAnswered ? 'Prossima' : 'Salta';
        const btnVariant: string = isAnswered ? '' : 'error-outline';
        return <Button icon="paginator-next" iconPosition="right" onClick={() => onClickNext()} variant={btnVariant}>
            {text} domanda
        </Button> 
    }
    
    const seeQuestionsButton: ReactElement = <Button variant="primary-outline" icon="todo-list" onClick={() => setShowQuestions(true)}>
                                                    Riepilogo domande</Button>

    const actionButons = () => {

        const questionsLeft: boolean = givenAns.filter(e => e.answerID !== null).length < questions.length;
        const text: string = questionsLeft ? 'Sicuro di voler terminare il quiz? Non tutte le domande sono state completate.' : 'Sicuro di voler terminare il quiz?';

        if (questions.length === 1) {

            return <Grid columns='3' className="">
                <div></div>
                <CustomConfirmAlert buttonText='Termina' icon="crud-save" variant="success" confirmText="Termina" confirmVariant="success"
                        text={text} confirm={() => stopSimulation()}/>
            </Grid>
        }

        if (currentNumQuest === 0) {

            return <Grid columns='4' className="">
                <div></div>
                { seeQuestionsButton }
                { nextQuestButton() }
            </Grid>
        }

        if (currentNumQuest > 0 && currentNumQuest < questions.length - 1) {
            return <Grid columns='3'>
                { prevQuestButon }
                { seeQuestionsButton }
                { nextQuestButton() }
            </Grid>
        }

        if (currentNumQuest === questions.length - 1) {
            return <Grid columns='3'>
                { prevQuestButon }
                { seeQuestionsButton }
                { questionsLeft && !skipQuestions(simulation) ?
                <CustomConfirmAlert buttonText='Termina' icon="crud-save" variant="success" confirmVariant="success"
                        text='Completare tutte le domande prima di terminare la simulazione. ATTENZIONE: Allo scadere del tempo le domande 
                        senza risposta verrano considerate non corrette.'/>
                :
                <CustomConfirmAlert buttonText='Termina' icon="crud-save" variant="success" confirmText="Termina" confirmVariant="success"
                        text={text} confirm={() => stopSimulation()}/> }
            </Grid>
        }
    }

    const stopSimulation = () => {
        if (isEnding.current) {
            return;
        }
        isEnding.current = true;

        let finalData: IGivenAns[] = updateQuestionsTime();
        if (!skipQuestions(simulation)) {
            finalData = finalData.map(g => { return { ...g, right: g.right === null ? false : g.right }})
        }
        const jsonData = {
            simulation: simulation,
            answers:    finalData,
            total_time: timer.time,
        } 
        jsonData.simulation.questions = jsonData.simulation.questions.map(q => {
            return {
                id: q.id,
                theme_id: q.theme_id,
                type: q.type
            }
        });
        endSimulation({
            customer_id: JSON.parse(getCookie("loggedUser")).id,
            simulation_id: simulationID,
            test_id: simulation.test_id, 
            old_flat_id: flatID,
            json_data: JSON.stringify(jsonData)
        });
        clearInterval(timerID.current);
        setTimer(p => ({ ...p, isRunning: false, intervalID: null }));
        setEndModal(true);
        window.setTimeout(() => history.push(`/quiz/${simulation.test_id}/results`), 2000);
    }

    const questionsAndAnswers = () => {
        if (currentNumQuest < 0 || questions.length === 0) {
            return <div></div>;
        }

        const totTimeInSeconds: number = parseInt(simulation.settings.data.time) * 60;
        const timeElapsedInSeconds: number = Math.floor(timer.time / 1000);
        const timeLeftInSeconds: number = totTimeInSeconds - timeElapsedInSeconds;
        const pauseIcon: string = timerID.current !== null ? '' : 'action-rotate-right';
        const pauseText: string = timerID.current !== null ? 'Pausa' : 'Riprendi';
        const isAnswered: boolean = givenAns.filter(g => g.questionID === currentQuestion.id && g.answerID !== null).length > 0;
        const showSolution: boolean = currentQuestion.metadata !== undefined && showQuiz && showRight(simulation) && isAnswered;

        return <div>
            <QuizTopBar numQuestion={currentNumQuest + 1} diff={getDifficulty(questions.map(e => e.data))} numGivenAns={numGivenAnswers} 
                    simulation={simulation} className="flex-wrap gap-4 desktop-max:gap-2" questionTime={timer.questTime} time={timer.time}/>
            <Hr className="bg-adjust-tone-18 my-4"/>
            <Row lastChild="to-right">
                <Button variant="primary-outline" icon={pauseIcon} onClick={onClickPause}>{pauseText}</Button> 
            </Row>

            { showQuiz &&
            <QuizHeader timeLeftInSeconds={timeLeftInSeconds} noStop={noStop(simulation)} endModal={endModal} currentQuestion={currentQuestion}/> }

            { showQuiz ?
            <QuizAnswers givenAns={givenAns} selectAnswer={selectAnswer} currentQuestion={currentQuestion} showRight={showRight(simulation)}/> 
            : <div className='text-center'> <H4>La simulazione è in pausa. Premi "riprendi" per continuare.</H4> </div> }

            { showSolution && <AnswerDescription question={currentQuestion}/> }
        </div>
    }

    const selectAnswer = async (e: IAnswer) => {
        const alreadyAnswered: boolean = givenAns[currentNumQuest].id !== 0;
        if (showRight(simulation) && givenAns.filter(g => g.answerID !== null).map(g => g.questionID).includes(currentQuestion.id)) {
            return;
        }

        const deselectAnswer: boolean = givenAns.map(d => d.answerID).includes(e.id.toString());
        const firstPart = givenAns.slice(0, currentNumQuest);
        const secondPart = givenAns.slice(currentNumQuest + 1, givenAns.length);
        const elem = givenAns[currentNumQuest];

        if (deselectAnswer) {
            editCustomerRecord(elem.id, { answer_id: null, right: null, time: timer.questTime });
            setGivenAns([ ...firstPart, { ...elem, answerID: null, right: null } , ...secondPart ]);
            setTimeToNext(0);
            return;
        }

        if (alreadyAnswered) {
            editCustomerRecord(elem.id, { answer_id: e.id, time: Math.round(timer.questTime / 1000), right: e.result === '1' });
            setGivenAns([ ...firstPart, { ...elem, answerID: e.id, right: e.result === '1', time: timer.questTime } , ...secondPart ]);
            setTimeToNext(timer.time);
            return;
        }

        const customerRecord = {
            simulation_flat_id: flatID,
            simulation_id: simulation.id,
            customer_id: JSON.parse(getCookie("loggedUser")).id,
            question_id: currentQuestion.id,
            answer_id: e.id,
            time: Math.round(timer.questTime / 1000),
            right: e.result === '1',
        };
        const res = await saveCustomerRecord(customerRecord);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        if (!data.status) {
            return;
        }
        setGivenAns([ ...firstPart, { ...elem, answerID: e.id, right: e.result === '1', id: data.status, time: timer.questTime } , ...secondPart ]);
        setTimeToNext(timer.time);
    }

    return <div className="bg-adjust-tone py-12 mobile:pt-4 view-limit desktop-max:p-4">
        { questionsAndAnswers() }

        { showQuiz && <Grid>{ actionButons() }</Grid> }

        {/* Modale */}
        <Modal footer={false} position="center" visible={endModal} onCancel={() => {}}>
            La simulazione è terminata.
        </Modal> 

        <QuestionRecap showQuest={showQuestions} setShowQuest={setShowQuestions} currentNumQuest={currentNumQuest} answers={givenAns}
                questions={questions} setCurrentNumQuest={setCurrentNumQuest} showRight={showRight(simulation)} endSimulation={stopSimulation}
                skipQuestions={skipQuestions(simulation)}/>         
    </div>
}

export default QuizPlay;
